import React from "react";
import {graphql} from "gatsby";
import Hero from "../components/Hero";
import StaticContent from "../components/StaticPage/StaticContent";
import TrustPilot from "../components/Core/TrustPilot";

export default function StaticPage({data}) {
    const staticPage = data.staticPage.pageBuilder;

    let propertiesBar;

    if (data.staticPage.properties !== null) {
        propertiesBar = data.staticPage.properties;
    }

    const content = (staticPage || [])
        .map((c) => {
            let el;

            switch (c._type) {
                case "hero":
                    el = <Hero key={c._key} {...c} />;
                    break;
                case "trustpilot":
                    el = <TrustPilot key={c._key} {...c} />;
                    break;
                case "copy":
                    el = <StaticContent key={c._key} {...c} propertiesBar={propertiesBar} />;
                    break;
                default:
                    el = null;
            }
            return el;
        });

    return (
      <>
        {content}
      </>
    );
}

export const query = graphql`
		query($slug: String!) {
        staticPage: sanityStaticPage( slug: { current: { eq: $slug } }) {
            name
            slug {
                current
            }
            properties {
              heading
              properties {
                name
                brandName
                imageUrl
                brandImage {
                    image {
                        asset {
                            fluid(maxWidth: 1200) {
                                ...GatsbySanityImageFluid
                            }
                        }
                    }
                }
                slug {
                    current
                }
                parkUrl
                stUrl
                bookingUrl
                bookingComUrl
                hoseasonsUrl
                region
                town
                county
                address
                postcode
                geopoint {
                    lat
                    lng
                }
                facilities
                accomodation
                description
              }
            }
            pageBuilder {
                ... on SanityCopy {
                    _key
                    _type
                    _rawCopy
                    showBothAccordion
                    showMobileAccordion
                    showDesktopAccordion
                }
                ... on SanityTrustpilot {
                    _key
                    _type
                }
                ... on SanityHero {
                    _key
                    _type
                    heading
                    tagline
                    searchForm {
                        heading
                        tagline
                        description
                        override
                        overrideDate
                        overrideNights
                        overrideGuests
                        color
                        cottageFilter
                        lodgeFilter
                        apartmentFilter
                        petFilter
                        hottubFilter
                        beachFilter
                        gardenFilter
                        wifiFilter
                        parkingFilter
                        fireFilter
                        babyFilter
                        poolFilter
                        luxuryFilter
                        gamesFilter
                        pubFilter
                        secludedFilter
                        accessibleFilter
                        enclosedFilter
                    }
                    whitelabelSearchForm {
                        heading
                        tagline
                        id
                        description
                        override
                        overrideDate
                        overrideNights
                        overrideGuests
                        color
                        scrollToForm
                        vrSort
                        apartmentFilter
                        holidayHomesFilter
                        villaFilter
                        hotelFilter
                        lodgeFilter
                        petsFilter
                        wifiFilter
                        coastalFilter
                        luxuryFilter
                        poolFilter
                        hotTubFilter
                    }
                    alignText
                    image {
                        asset {
                            fluid(maxWidth: 1200) {
                                    ...GatsbySanityImageFluid
                            }
                        }
                    }
                }
			}
			
        }
		regions: allSanityRegion {
			nodes {
				name
				id
				slug {
					current
				}
                showInNav
			}
		}
		settings: sanitySiteSettings(_id: {eq: "siteSettings"})  {
			description
			id
			keywords
			primaryColor {
				hex
			}
			secondaryColor {
				hex
			}
			title
			logo {
				asset {
					fixed {
						src
					}
				}
			}
			favicon {
				asset {
					fixed(height: 48, width: 48) {
						src
					}
				}
			}
			image {
				asset {
				fixed(height: 600, width: 1200) {
					src
				}
				}
			}
            signUpDisplay
          signUpBackgroundColor
          signUpHeading
          zapierEndpoint
		}
	}
`;